import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import { Container, Section, Column, Columns, Title } from "bloomer"
import Persons from "../components/aboutUs/persons"
import IntroText from "../components/aboutUs/introText"
const AboutUs = ({ data }) => {
  const about = data.sanityAbout
  return (
    <main>
      <Layout>
        <SEO title="About us" />
        <Section>
          <Container>
            <Column>
              <IntroText about={about} />
            </Column>
          </Container>
          <Container>
            <Title className="title is-2 introTitle">Our staff</Title>
            <Columns>
              {data.allSanityPerson.edges.map(node => (
                <Persons persons={node} />
              ))}
            </Columns>
          </Container>
        </Section>
      </Layout>
    </main>
  )
}
export const query = graphql`
  {
    allSanityPerson {
      edges {
        node {
          _key
          _rawBio
          name
          linkedInUrl
          possition
          twitterHandle
          twitterLink
          image {
            asset {
              fixed(width: 400, height: 300) {
                srcWebp
              }
              url
            }
            alt
          }
        }
      }
    }
    sanityAbout {
      title
      _rawBody2
      _rawBody1
      _rawBody3
      titleBody1
      titleBody2
      titleBody3
    }
  }
`
export default AboutUs
