import React from "react"
import { Column, Columns, Title } from "bloomer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faUniversalAccess,
  faHandHoldingSeedling,
  faHandshakeAlt,
} from "../../fontAwesome/pro-duotone-svg-icons"

const BlockContent = require("@sanity/block-content-to-react")

const IntroText = ({ about }) => {
  return (
    <>
      <Title className="title is-1 introTitle" id="main">
        {about.title}
      </Title>
      <Columns className="" isSize="small">
        <Column className="has-text-centered ">
          <FontAwesomeIcon
            icon={faUniversalAccess}
            className="faIconColors fa-5x"
            alt="Icon pointer right"
          />
          <Title tag="h2" className="title is-2 titleAbout">
            {about.titleBody1}
          </Title>{" "}
          <hr />
          <BlockContent blocks={about._rawBody1} />
        </Column>
        <Column className="has-text-centered ">
          <FontAwesomeIcon
            icon={faHandHoldingSeedling}
            className="faIconColors fa-5x"
            alt="Icon pointer right"
          />
          <Title tag="h2" className="title is-2 titleAbout">
            {about.titleBody2}
          </Title>{" "}
          <hr />
          <BlockContent blocks={about._rawBody2} />
        </Column>
        <Column className="has-text-centered ">
          <FontAwesomeIcon
            icon={faHandshakeAlt}
            className="faIconColors fa-5x"
            alt="Icon pointer right"
          />
          <Title tag="h2" className="title is-2 titleAbout">
            {about.titleBody3}
          </Title>{" "}
          <hr />
          <BlockContent blocks={about._rawBody3} />
        </Column>
      </Columns>
    </>
  )
}

export default IntroText
