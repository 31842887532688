import React from "react"
import {
  Column,
  Card,
  CardImage,
  CardContent,
  Media,
  Title,
  Subtitle,
  Content,
} from "bloomer"
import { MediaContent } from "bloomer/lib/components/Media/MediaContent"

const BlockContent = require("@sanity/block-content-to-react")
const Persons = ({ persons }) => {
  const personArray = persons.node
  return (
    <>
      <Column className="is-one-third">
        <Card>
          <CardImage>
            {personArray.image && (
              <img
                src={personArray.image.asset.fixed.srcWebp}
                // src={personArray.image.asset.url}
                alt={personArray.image.alt}
              />
            )}
          </CardImage>
          <CardContent>
            <Media>
              <MediaContent>
                <Title className="is-4 introTitle">
                  {personArray.name && <p> {personArray.name} </p>}
                </Title>
                {personArray.possition && (
                  <Subtitle className="is-5">
                    {" "}
                    {personArray.possition}{" "}
                  </Subtitle>
                )}
              </MediaContent>
            </Media>
            <Content>
              {personArray._rawBio && (
                <BlockContent blocks={personArray._rawBio} />
              )}
              {personArray.linkedInUrl && (
                <a href={personArray.linkedInUrl}>
                  {personArray.name} on LinkedIn
                </a>
              )}
              {" | "}
              {personArray.twitterLink && personArray.twitterHandle && (
                <a href={personArray.twitterLink}>
                  Twitter@{personArray.twitterHandle}
                </a>
              )}
              <br />
            </Content>
          </CardContent>
        </Card>
      </Column>

      {/*      Data is fetched to this component from a loop in the aboutUs page
      Since the data is already looped we don't have to perform any additional loops here unless we have a nested array
      This component also perfoms checks (if-else) statements (personArray.image && is an if-else)
      Without these checks the build for this site would fail, and the site would fail locally due to Null being returned
      And the component expecting some data. */}
    </>
  )
}

export default Persons
